import Image from 'next/image'
import * as React from 'react'

import { Typography } from 'components/typography'
import { Button } from 'components/button'
import { MintModal } from '../modal'

import styles from './mint-overlay.module.scss'
import { useMintMachine } from 'utils/use-mint-machine'
import { isMintLive } from 'utils/utils'
import { Countdown } from 'components/countdown'

export const MintOverlay = ({
  bookId,
  title,
  bookSlug,
  projectSlug,
  firstEditionCollectionId,
  firstEditionDurationInDays,
  mintStartDate,
  secondEditionMintStartDate,
  secondEditionCollectionId,
  mintCost,
  fiatCost,
}: {
  bookId: string
  title: string
  bookSlug: string
  projectSlug: string
  firstEditionCollectionId: number
  secondEditionCollectionId: number
  mintStartDate: Date
  secondEditionMintStartDate: Date
  firstEditionDurationInDays: number
  mintCost: number
  fiatCost: number
}) => {
  const { state, send } = useMintMachine({
    bookId,
    firstEditionCollectionId,
    firstEditionDurationInDays,
    mintStartDate,
    secondEditionCollectionId,
    bookSlug,
    projectSlug,
  })

  const mint = React.useMemo(() => {
    return isMintLive({
      firstEditionDurationInDays,
      firstEditionMintStartDate: mintStartDate,
      secondEditionMintStartDate,
    })
  }, [])

  const [secondsFirstEdition, setSecondsFirstEdition] = React.useState(() => {
    const now = new Date()
    const endDate = new Date(mintStartDate)
    endDate.setDate(endDate.getDate() + firstEditionDurationInDays)

    const diff = endDate.getTime() - now.getTime()
    return Math.floor(diff / 1000)
  })

  React.useEffect(() => {
    let timeout: any

    if (secondsFirstEdition > 0) {
      timeout = setTimeout(() => {
        setSecondsFirstEdition(secondsFirstEdition - 1)
      }, 1000)
    }

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [secondsFirstEdition])

  const onMintClick = () => {
    send({
      type: 'SET_BOOK',
      bookId: bookId,
      title: title,
      bookSlug,
      projectSlug,
      firstEditionCollectionId: firstEditionCollectionId!,
      firstEditionDurationInDays: firstEditionDurationInDays,
      secondEditionCollectionId: secondEditionCollectionId!,
      mintStartDate: mintStartDate!,
      secondEditionMintStartDate: secondEditionMintStartDate,
      mintCost: mintCost,
      fiatCost: fiatCost,
    })
  }

  return (
    <>
      <div className={styles.overlay}>
        <Image
          src="/images/mint-overlay.png"
          width={113}
          height={94}
          alt="Robot holding beaker"
        />
        <div className={styles.overlay__content}>
          <Typography variant="button-regular">
            Machines of Mana · Book 3 · Part 1
          </Typography>
          <div className={styles.overlay__grid}>
            <>
              <CountdownSection
                title={`Mint 1st Edition`}
                onClick={onMintClick}
                started
                seconds={secondsFirstEdition}
              />
              <CountdownSection
                title="Mint 2nd Edition"
                onClick={onMintClick}
                started={false}
                seconds={100}
              />
            </>
            <MintModal state={state} send={send} />
          </div>
        </div>
      </div>
      {/* Mobile version */}
      <Button
        onClick={onMintClick}
        style="unstyled"
        className={styles.mintButton}
        fitContent
      >
        <span className={styles.mintButton__wrapper}>
          <Image
            src="/images/mint-overlay.png"
            width={113}
            height={94}
            alt="Robot holding beaker"
          />
          <span className={styles.mintButton__cta}>Mint now</span>
        </span>
      </Button>
    </>
  )
}

const CountdownSection = ({
  title,
  seconds,
  started,
  onClick,
}: {
  title: string
  seconds: number
  started: boolean
  onClick: () => void
}) => {
  if (!started) {
    return (
      <div>
        <Button isDisabled={true}>{title}</Button>
        <Typography variant="body-regular-book" noMargins>
          Coming soon
        </Typography>
      </div>
    )
  }

  if (seconds <= 0) {
    return (
      <div>
        <Button isDisabled={true}>{title}</Button>
        <Typography variant="body-regular-book" noMargins>
          MINT HAS ENDED
        </Typography>
      </div>
    )
  }

  return (
    <div>
      <Button onClick={onClick}>{title}</Button>
      <Countdown seconds={seconds} text="ENDS IN " />
    </div>
  )
}
